import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracaoArquivos } from './configuracao-arquivos/configuracao-arquivos.model';
import { PageResult } from '../shared/service/generic.service';
import { VsCrudService } from '../shared/service/vs-crud.service';
import { MessageService } from '../shared/service/message.service';


@Injectable()
export class ConfiguracaoArquivosService extends VsCrudService<ConfiguracaoArquivos> {

  public url: string = "api/configuracaoArquivos";
  pageResult: PageResult = new PageResult();

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }



}

