import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./shared/auth/auth.guard";
import { ViewComponent } from './view/view.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login',                        component: LoginComponent , canActivate: [AuthGuard]},
  { path: 'dashboard',                    component: DashboardComponent , canActivate: [AuthGuard]},
  { path: 'view',                         component:     ViewComponent,canActivate: [AuthGuard]} ,
  { path: 'membresia',                    loadChildren: () => import('../app/administrativo/administrativo.module').then(m => m.AdministrativoModule),canActivate: [AuthGuard]} ,
  { path: 'financeiro',                   loadChildren: () => import('../app/financeiro/financeiro.module').then(m => m.FinanceiroModule),canActivate: [AuthGuard]},
  { path: 'seguranca',                    loadChildren: () => import('../app/seguranca/seguranca.module').then(m => m.SegurancaModule),canActivate: [AuthGuard]},
  { path: 'relatorios',                   loadChildren: () => import('../app/relatorios/relatorios.module').then(m => m.RelatoriosModule),canActivate: [AuthGuard]},
  { path: 'configuracoes',                loadChildren: () => import('../app/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
