import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { GlobalsVariablesService } from '../shared/service/global-variables.service';
import { Util } from '../utilitarios/util';
import { Dashboard } from './dashboard.model';
import { DashboardService } from './dashboard.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    usuarioPermissao;
    public dashboard = new Dashboard();
    public valorVendas: Number;
    public valorCompras: Number;
    public valorContasAPagar: Number;
    public valorContasAReceber: Number;
    public vendasAnual;
    public vendasMensal;
    public compras;
    public receberPagar;
    public financeiro;
    private lojaId;
    public filtro: FormGroup;
    public periodo = new Array();
    public periodoFormatado = new Array();
    public pt_BR: any;
    public version: string = environment.version;

    constructor(
        private router: Router,
        private _dashboardService: DashboardService,
        private fb: FormBuilder,
        public globalsVariablesService: GlobalsVariablesService) {
        this.buildForm();
    }



    ngOnInit() {
        this.pt_BR = Util.traducaoDataCalendar();
        //PARA PREENCHIMENTO DO P-CALENDAR COM O PERÍODO
        let dataVigente = new Date();
        let dataInicio = new Date(dataVigente.getFullYear(), dataVigente.getMonth(), 1);
        this.periodo.push(dataInicio);
        let dataFim = new Date(dataVigente.getFullYear(), dataVigente.getMonth() + 1, 0);
        this.periodo.push(dataFim);
        this.filtro.get('periodo').setValue(this.periodo);

        //PARA PESQUISA DO PERÍODO
        this.periodoFormatado.push(Util.formatarData(this.periodo[0], '-'));
        this.periodoFormatado.push(Util.formatarData(this.periodo[1], '-'));


        this.valorCompras = 0.00;
        this.valorVendas = 0.00;
        this.valorContasAPagar = 0.00;
        this.valorContasAReceber = 0.00;
        this.atualizarDados();
        // this.reload();
    }

    buildForm(): void {
        this.filtro = this.fb.group({
            'periodo': ['']
        });
    }

    onSelectDate() {
        this.periodo = new Array();
        this.periodoFormatado = new Array();
        this.periodo = this.filtro.get('periodo').value;

        if (this.periodo[1] != null) {
            //SE SELECIONADO AS 2 DATAS

            this.periodoFormatado.push(Util.formatarData(this.periodo[0], '-'));
            this.periodoFormatado.push(Util.formatarData(this.periodo[1], '-'));
            console.log("Selecionado as 2 Datas: \n", this.periodoFormatado);
            this.atualizarDados();
        }
    }

    atualizarDados() {
        var interval = setInterval(() => {
            this.lojaId = this.globalsVariablesService.getUsuarioLogado.lojaId;
            if (this.lojaId != null) {
                clearInterval(interval);
                // Foi recuperado totalmente as permissões
                this._dashboardService.totalVendasRealizadas(localStorage.getItem("tenant"), this.periodoFormatado).then(res => {
                    this.dashboard = res;

                    this.valorVendas = this.dashboard.valorVendas;
                    this.valorCompras = this.dashboard.valorCompras;
                    this.valorContasAPagar = this.dashboard.valorDuplicatasPagar;
                    this.valorContasAReceber = this.dashboard.valorDuplicatasReceber;

                    this.atualizarGraficos();

                });
            }
        }, 600);
    }

    atualizarGraficos() {
        this.vendasMensal = {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            datasets: [
                {
                    label: 'Ano Anterior',
                    data: [65, 69, 80, 81, 50, 45, 30, 35, 33, 38, 43, 44],
                    borderColor: '#4bc0c0',
                    backgroundColor: '#4bd2d29c',
                    fill: 'start'
                },
                {
                    label: 'Ano Atual',
                    data: [28, 26, 20, 19, 40, 49, 50, 55, 62, 67, 60, 58],
                    borderColor: '#565656',
                    backgroundColor: '#90909094',
                    fill: 'start'
                }
            ]
        }

        this.vendasAnual = {
            labels: ['Ano Anterior', 'Ano Atual'],
            datasets: [
                {
                    label: 'Vendas',
                    data: [10265, 1039],
                    backgroundColor: '#4bc0c0'
                }
            ]
        }

        this.compras = {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            datasets: [
                {
                    label: 'Ano Anterior',
                    data: [65, 69, 80, 81, 50, 45, 30, 35, 33, 38, 43, 44],
                    fill: 'start',
                    borderColor: '#f1f1f1',
                    fontColor: '#f1f1f1'
                },
                {
                    label: 'Ano Atual',
                    data: [75, 89, 83, 81, 52, 35, 33, 91, 96, 61, 56, 61],
                    fill: 'start',
                    borderColor: '#676767',
                    fontColor: '#f1f1f1'
                }
            ]
        }

        this.receberPagar = {
            labels: ['Pagar', 'Receber'],
            datasets: [
                {
                    data: [this.valorContasAPagar, this.valorContasAReceber],
                    backgroundColor: [
                        "#FF6384",
                        "#30ce85"
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#30ce85"
                    ]
                }
            ]
        }

        this.financeiro = {
            labels: ['1 ao 5', '6 ao 10', '11 ao 15', '16 ao 20', '21 ao 25', '26 ao 31'],
            datasets: [
                {
                    label: 'Entrada',
                    data: [845, 549, 780, 451, 1454, 889],
                    backgroundColor: '#30ce85'
                },
                {
                    label: 'Saída',
                    data: [-145, -180, -254, -122, -211, -440],
                    backgroundColor: '#FF6384'
                }
            ]
        }
    }


    reload(): void {
        let acesso = localStorage.getItem("primeiroAcesso");

        if (acesso == 'true') {
            window.location.reload();
            localStorage.removeItem("primeiroAcesso")
        }
        else
            return;

    }
}

