import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Setup } from './setup.model';




@Injectable()
export class SetupService extends VsCrudService<Setup> {
  
  public url: string = "api/setups";
  
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }
   
    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

      public getSetup() {
        let teste;
    
        return this.get(`${this.url}/findBy/`)
          .toPromise()
          .then(res =><Setup>res);
      }




}


